
import { mapState } from 'vuex'
import DataTable from '~/mixins/dataTable'
import fingerprint from '~/mixins/fingerprint'

export default {
    name: 'DesktopNav',
    mixins: [DataTable, fingerprint],
    props: {
        links: {
            type: Object,
            default: () => {},
        },
        languages: {
            type: Array,
            default: () => [],
        },
        token: {
            type: String,
            default: '',
        },
    },
    data: () => ({
        form: {
            player_id: null,
            password: null,
            reg_fingerprint: null,
        },
        showLogin: false,
        loading: false,
        showPassword: false,
    }),
    computed: {
        ...mapState({
            player: (state) => state.player.player,
            loginPopup: (state) => state.player.loginPopup,
        }),
        theme() {
            return this.$vuetify.theme.dark ? 'dark' : 'light'
        },
        oppositeTheme() {
            return !this.$vuetify.theme.dark ? 'dark' : 'light'
        },
    },
    watch: {
        loginPopup(v) {
            if (v) {
                this.showLogin = true
            }
        },
    },
    async mounted() {
        this.form.reg_fingerprint = await this.visitorId()
    },
    methods: {
        onLogin() {
            this.loading = true
            this.loading = false
        },
    },
}
